import { atom } from "recoil";

export const addPuzzleModalAtom = atom({
  key: "addPuzzleIsOpen",
  default: false,
});

export const confirmModalAtom = atom({
  key: "confirmIsOpen",
  default: false,
});

export const confirmModalContentAtom = atom({
  key: "confirmModalContent",
  default: { title: "", message: "", confirmCallback: null },
});
