import React, { useContext, useState, useEffect } from "react";
import { signInWithGoogle, signOut, supabase } from "../services/supabase";
import { useRecoilState } from "recoil";
import { authAtom } from "../atoms/authAtom";

const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useRecoilState(authAtom);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const session = supabase.auth.session();
    setUser(session?.user ?? null);
    setLoading(false);

    const { data: listener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        setUser(session?.user ?? null);
        setLoading(false);
      }
    );
    return () => {
      listener?.unsubscribe();
    };
  }, [setUser]);

  const value = {
    signIn: signInWithGoogle,
    signOut: signOut,
    user,
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
