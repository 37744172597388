import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useStopwatch } from "react-timer-hook";
import { DateTime } from "luxon";
import defaultPuzzleImg from "../../assets/images/puzzletimeDefault.jpg";
import { useNavigate } from "react-router-dom";
import { getTimeString, parseTotalTime } from "../../utils";

function PuzzleCard({
  puzzleId,
  puzzleName,
  pieceCount,
  timeSpent,
  timerStopCallback,
}) {
  const [isTiming, setIsTiming] = useState(false);
  const { hours, minutes, seconds, reset, pause, isRunning } = useStopwatch({
    autoStart: false,
  });
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const navigator = useNavigate();

  const toggleTimer = () => {
    setIsTiming((currVal) => {
      const newIsTiming = !currVal;
      if (newIsTiming) {
        setStartTime(DateTime.local());
        reset();
      } else {
        pause();
        setEndTime(DateTime.local());
      }
      return newIsTiming;
    });
  };

  useEffect(() => {
    if (startTime && endTime && !isTiming) {
      timerStopCallback(puzzleId, startTime, endTime);
    }
  }, [startTime, endTime, isTiming, timerStopCallback, puzzleId]);

  return (
    <div className="md:w-1/3 p-1.5">
      <div className="min-h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
        <img
          className="lg:h-48 md:h-36 w-full object-cover object-center"
          src={defaultPuzzleImg}
          alt="blog"
        />
        <div className="min-h-full p-6 bg-neutral-800">
          <h1 className="title-font text-lg font-medium text-gray-300 mb-1 uppercase">
            {puzzleName}
          </h1>
          <h2 className="tracking-widest text-xs title-font font-medium text-gray-300 mb-3">
            {pieceCount} Pieces
          </h2>
          <p className="leading-relaxed">
            Total Time Spent: {parseTotalTime(timeSpent)}
          </p>

          <p className="h-9 leading-relaxed mb-2 text-xl">
            {isRunning &&
              `Current Time Spent: ${getTimeString(hours, minutes, seconds)}`}
          </p>

          <div className="flex items-center flex-wrap gap-4 w-full ">
            <button
              type="button"
              className={`text-white md:mb-2 lg:mb-0 py-4 rounded-md ${
                isTiming ? "bg-purple-800" : "bg-green-800"
              } grow font-semibold`}
              onClick={toggleTimer}
            >
              {!isTiming ? "Start Timer" : "Stop Timer"}
            </button>
            <button
              type="button"
              className="text-white md:mb-2 lg:mb-0 py-4 rounded-md bg-cyan-800 grow font-semibold"
              onClick={() => navigator(`/puzzles/${puzzleId}`)}
            >
              View Details
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

PuzzleCard.propTypes = {
  puzzleId: PropTypes.string.isRequired,
  puzzleName: PropTypes.string.isRequired,
  pieceCount: PropTypes.number.isRequired,
  timerStopCallback: PropTypes.func.isRequired,
  timeSpent: PropTypes.object,
};

export default PuzzleCard;
