import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  process.env.REACT_APP_PUBLIC_SUPABASE_URL,
  process.env.REACT_APP_PUBLIC_SUPABASE_ANON_KEY
);

const signInWithGoogle = async () => {
  const { error } = await supabase.auth.signIn(
    {
      provider: "google",
    },
    { redirectTo: process.env.REACT_APP_AUTH_REDIRECT }
  );
  if (error) {
    console.error("SIGN IN ERROR", error);
  }
};

const signOut = async () => {
  const { error } = await supabase.auth.signOut();
  if (error) {
    console.error("SIGN OUT ERROR:", error);
  }
};

export { supabase, signInWithGoogle, signOut };
