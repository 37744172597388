import { Outlet } from "react-router-dom";
import Header from "../components/Header/Header";
import ConfirmationModal from "../components/Modal/ConfirmationModal";
import AddPuzzleModal from "./Puzzle/AddPuzzleModal";

function Layout() {
  return (
    <div className="min-h-screen flex flex-col bg-neutral-900 text-gray-300">
      <Header />
      <main className="container mx-auto p-4">
        <Outlet />
      </main>
      <AddPuzzleModal />
      <ConfirmationModal />
    </div>
  );
}

export default Layout;
