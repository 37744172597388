import React, { useCallback, useEffect } from "react";
import PuzzleCard from "../../components/Puzzle/PuzzleCard";
import { authAtom } from "../../atoms/authAtom";
import { puzzleAtom } from "../../atoms/puzzleAtom";
import { useRecoilValue, useRecoilState } from "recoil";
import { supabase } from "../../services/supabase";
import { useNavigate } from "react-router-dom";
import { getTotalTimeObject } from "../../utils";

function Puzzles() {
  const user = useRecoilValue(authAtom);
  const [puzzles, setPuzzles] = useRecoilState(puzzleAtom);
  const navigator = useNavigate();

  const getPuzzles = useCallback(async () => {
    try {
      let { data, error } = await supabase
        .from("puzzles")
        .select()
        .eq("user_id", user.id)
        .select(`*, entries(*)`)
        .order("created_at", { ascending: false });
      if (error) {
        throw error;
      }
      if (data) {
        //calculate total time for each puzzle
        data.forEach((puzzle) => {
          const totalDiff = getTotalTimeObject(puzzle.entries);
          puzzle.totalTime = totalDiff
            ? totalDiff.normalize().toObject()
            : null;
        });
        setPuzzles(data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [user.id, setPuzzles]);

  const addEntry = async (puzzleId, startTime, endTime) => {
    try {
      const entry = {
        puzzle_id: puzzleId,
        start: startTime,
        end: endTime,
      };
      let { error } = await supabase.from("entries").upsert(entry);
      if (error) {
        throw error;
      }
    } catch (error) {
      console.log(error);
    } finally {
      navigator(0);
    }
  };

  useEffect(() => {
    getPuzzles();
  }, [user, getPuzzles]);

  return (
    <div className="flex flex-wrap">
      {puzzles &&
        puzzles.map((puzzle) => (
          <PuzzleCard
            key={puzzle.id}
            puzzleId={puzzle.id}
            puzzleName={puzzle.name}
            pieceCount={puzzle.piece_count}
            timeSpent={puzzle.totalTime}
            timerStopCallback={addEntry}
          />
        ))}
    </div>
  );
}

export default Puzzles;
