import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { authAtom } from "./atoms/authAtom";
import { supabase } from "./services/supabase";
import Login from "./pages/Login/Login";
import Puzzle from "./pages/Puzzle/Puzzle";
import Puzzles from "./pages/Puzzle/Puzzles";
import { AuthProvider } from "./contexts/Auth";
import { PrivateRoute } from "./components/Nav/PrivateRoute";
import "./App.css";

function App() {
  const setUser = useSetRecoilState(authAtom);

  supabase.auth.onAuthStateChange((event, session) => {
    if (session?.user) {
      setUser(session.user);
    } else {
      setUser(null);
    }
  });

  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/puzzles" element={<PrivateRoute />}>
            <Route index element={<Puzzles />} />
            <Route path=":id" element={<Puzzle />} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
