import { Menu } from "@headlessui/react";
import { UserCircleIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/Auth";

function AvatarDropdown() {
  const { signOut } = useAuth();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    await signOut();
    navigate("/");
  };

  return (
    <div className="">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button>
            <UserCircleIcon className="w-8 h-8" />
          </Menu.Button>
        </div>

        <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right divide-y border-2 border-gray-200 border-opacity-60 bg-neutral-800 divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="p-1">
            <Menu.Item>
              <button
                className="bg-neutral-800 group flex rounded-md items-center w-full px-2 py-2 text-sm"
                onClick={handleSignOut}
              >
                Logout
              </button>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Menu>
    </div>
  );
}

export default AvatarDropdown;
