import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/Auth";

function Login() {
  const navigate = useNavigate();
  const { user, signIn } = useAuth();

  useEffect(() => {
    if (user) {
      navigate("/puzzles");
    }
  }, [user, navigate]);

  const handleSignIn = async () => {
    const { error } = await signIn();
    if (error) {
      console.log(error);
    } else {
      navigate("/puzzles");
    }
  };

  return (
    <div className="h-screen flex flex-col bg-neutral-900 text-gray-300 justify-center items-center">
      <div className="rounded-lg shadow-md shadow-black flex flex-col items-center px-16 py-10 bg-neutral-800">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-32 w-32"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"
          />
        </svg>
        <h1 className="text-3xl font-bold text-center mt-3">Puzzle Time</h1>
        <button
          className="bg-google-normal focus:bg-google-focus disabled:bg-google-disabled visited:bg-google-pressed bg-no-repeat w-[191px] h-[46px] mt-6"
          onClick={handleSignIn}
        ></button>
      </div>
    </div>
  );
}

export default Login;
