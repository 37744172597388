import React from "react";
import { Dialog } from "@headlessui/react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  confirmModalAtom,
  confirmModalContentAtom,
} from "../../atoms/modalAtom";

function ConfirmationModal() {
  const [isOpen, setIsOpen] = useRecoilState(confirmModalAtom);
  const content = useRecoilValue(confirmModalContentAtom);

  const onCancel = () => {
    setIsOpen(false);
  };

  const onConfirm = () => {
    content.confirmCallback();
  };

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-10 overflow-y-auto"
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <div className="min-h-screen px-4 text-center">
        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75" />

        <span className="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>

        <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-neutral-900 text-gray-300 shadow-xl rounded-2xl">
          <div className="flex flex-col items-center">
            <Dialog.Title as="h3" className="text-2xl font-medium leading-6">
              {content.title}
            </Dialog.Title>

            <div className="mt-6 p-6 text-center">
              <h4 className="text-xl">{content.message}</h4>
            </div>
          </div>
          <div className="flex flex-row-reverse gap-3 mt-6">
            <button
              type="button"
              className="bg-green-800 text-white w-1/4 py-2 rounded font-semibold"
              onClick={onConfirm}
            >
              Yes
            </button>
            <button
              type="button"
              className="bg-red-600 text-white w-1/4 py-2 rounded font-semibold"
              onClick={onCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default ConfirmationModal;
