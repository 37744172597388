import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { puzzleAtom, selectedPuzzleAtom } from "../../atoms/puzzleAtom";
import _ from "lodash";
import { supabase } from "../../services/supabase";
import defaultPuzzleImg from "../../assets/images/puzzletimeDefault.jpg";
import { getTotalTimeObject, parseTotalTime } from "../../utils";
import { Tab } from "@headlessui/react";
import {
  confirmModalAtom,
  confirmModalContentAtom,
} from "../../atoms/modalAtom";

function Puzzle() {
  const { id } = useParams();
  const puzzles = useRecoilValue(puzzleAtom);
  const [selectedPuzzle, setSelectedPuzzle] =
    useRecoilState(selectedPuzzleAtom);
  const [loading, setLoading] = useState(true);
  const setIsConfirmOpen = useSetRecoilState(confirmModalAtom);
  const setConfirmContent = useSetRecoilState(confirmModalContentAtom);
  const navigator = useNavigate();

  const populateMissingTimeData = (puzzle) => {
    const updatedPuzzle = { ...puzzle };
    if (!updatedPuzzle.hasOwnProperty("totalTime")) {
      const totalDiff = getTotalTimeObject(updatedPuzzle.entries);
      updatedPuzzle.totalTime = totalDiff
        ? totalDiff.normalize().toObject()
        : null;
    }
    updatedPuzzle.entryCount = updatedPuzzle.entries.length;
    return updatedPuzzle;
  };

  const getSelectedPuzzle = useCallback(async () => {
    const selectedPuzzle = _.find(puzzles, { id: id });

    if (!selectedPuzzle) {
      try {
        const { data, error } = await supabase
          .from("puzzles")
          .select()
          .eq("id", id)
          .select(`*, entries(*)`);
        if (error) {
          throw error;
        }
        if (data) {
          const puzzle = populateMissingTimeData(data[0]);

          setSelectedPuzzle(puzzle);
          setLoading(false);
          return puzzle;
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      const puzzle = populateMissingTimeData(selectedPuzzle);
      setSelectedPuzzle(puzzle);
      setLoading(false);
    }
  }, [id, setSelectedPuzzle, puzzles]);

  const toggleDeleteModal = () => {
    setConfirmContent({
      title: "Delete Puzzle",
      message: `Are you sure you want to delete ${selectedPuzzle.name}?`,
      confirmCallback: deletePuzzle,
    });
    setIsConfirmOpen(true);
  };

  const deletePuzzle = async () => {
    try {
      const { error } = await supabase
        .from("puzzles")
        .delete()
        .eq("id", selectedPuzzle.id);
      if (error) {
        throw error;
      }
      navigator("/puzzles", { replace: true });
      setIsConfirmOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSelectedPuzzle();
  }, [getSelectedPuzzle]);

  if (loading) {
    return <div>Loading</div>;
  }

  return (
    <div className="lg:w-4/5 mx-auto flex flex-wrap">
      <div className="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
        <h1 className="text-gray-300 text-3xl title-font font-medium mb-4">
          {selectedPuzzle.name}
        </h1>

        <Tab.Group>
          <Tab.List className="flex mb-4 w-full">
            <Tab
              className={({ selected }) =>
                `puzzle-detail-tab ${
                  selected
                    ? "puzzle-detail-tab-selected"
                    : "puzzle-detail-tab-deselected"
                }`
              }
            >
              Details
            </Tab>
            <Tab
              className={({ selected }) =>
                `puzzle-detail-tab ${
                  selected
                    ? "puzzle-detail-tab-selected"
                    : "puzzle-detail-tab-deselected"
                }`
              }
            >
              Stats
            </Tab>
          </Tab.List>

          <Tab.Panels>
            <Tab.Panel>
              <img
                alt="ecommerce"
                className="aspect-video object-cover object-center rounded mb-4"
                src={defaultPuzzleImg}
              />
              <div className="flex border-t border-gray-200 py-2">
                <span className="text-gray-300">Number of Pieces</span>
                <span className="ml-auto text-gray-300">
                  {selectedPuzzle.piece_count}
                </span>
              </div>
              <div className="flex border-t border-gray-200 py-2">
                <span className="text-gray-300">Total Time Spent</span>
                <span className="ml-auto text-gray-300">
                  {parseTotalTime(selectedPuzzle.totalTime)}
                </span>
              </div>
              <div className="flex border-t border-gray-200 py-2">
                <span className="text-gray-300">Total Puzzle Sessions</span>
                <span className="ml-auto text-gray-300">
                  {selectedPuzzle.entryCount}
                </span>
              </div>
              <div className="flex border-t  border-gray-200 py-2">
                <span className="text-gray-300">Start Date</span>
                <span className="ml-auto text-gray-300">5/13/2022</span>
              </div>
              <div className="flex border-t border-b mb-6 border-gray-200 py-2">
                <span className="text-gray-300">Completion Date</span>
                <span className="ml-auto text-gray-300">Still in Progress</span>
              </div>
            </Tab.Panel>

            <Tab.Panel>
              <div className="flex flex-col h-96 justify-center items-center">
                <h1 className="text-2xl">Stats Coming Soon</h1>
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>

        <div className="flex flex-col gap-3">
          <button className="text-white text-lg font-semibold rounded w-full bg-cyan-800 p-2 border-0 inline-flex items-center justify-center">
            Edit Puzzle Details
          </button>
          <button
            className="text-white text-lg font-semibold rounded w-full bg-red-600 p-2 border-0 inline-flex items-center justify-center"
            onClick={toggleDeleteModal}
          >
            Delete Puzzle
          </button>
        </div>
      </div>
    </div>
  );
}

export default Puzzle;
