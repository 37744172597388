import React from "react";
import { Formik, Form } from "formik";
import InlineField from "../../components/Form/InlineField";
import * as Yup from "yup";

const AddPuzzleSchema = Yup.object().shape({
  name: Yup.string()
    .required("Required")
    .min(3, "Name must be at least 3 characters"),
  pieceCount: Yup.number().required("Required"),
});

function AddPuzzleForm(props) {
  return (
    <Formik
      initialValues={{
        name: "",
        pieceCount: "",
      }}
      validationSchema={AddPuzzleSchema}
      onSubmit={async (values) => {
        props.submitCallback(values);
      }}
    >
      {({ errors, touched, isValid }) => (
        <Form className="flex flex-col">
          <InlineField name="name" id="name" label="Puzzle Name" type="text" />

          <InlineField
            name="pieceCount"
            id="pieceCount"
            label="Number of Pieces"
            type="number"
          />

          <div className="flex flex-row-reverse gap-2 pt-3">
            <button
              className="rounded bg-green-700 w-20 py-1 text-white"
              type="submit"
              disabled={!isValid}
            >
              Save
            </button>
            <button
              className="rounded bg-red-800 w-20 py-1 text-white"
              type="button"
              onClick={props.cancelCallback}
            >
              Cancel
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default AddPuzzleForm;
