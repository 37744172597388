import { ErrorMessage, Field } from "formik";
import React from "react";

function InlineField(props) {
  return (
    <div className="flex flex-col">
      <div className="field-group">
        <label htmlFor={props.name} className="font-semibold">
          {props.label}
        </label>
        <Field
          id={props.id}
          name={props.name}
          type={props.type}
          className="form-field"
        />
      </div>
      <div className="flex flex-row-reverse mt-0 mb-3 text-red-500 font-semibold">
        <ErrorMessage name={props.name} />
      </div>
    </div>
  );
}

export default InlineField;
