import { DateTime } from "luxon";

export const getTotalTimeObject = (entries) => {
  let totalDiff = null;
  entries.forEach((entry) => {
    const start = DateTime.fromISO(entry.start);
    const end = DateTime.fromISO(entry.end);
    const diff = end.diff(start, ["hours", "minutes", "seconds"]);
    if (!totalDiff) {
      totalDiff = diff;
    } else {
      totalDiff = totalDiff.plus(diff);
    }
  });
  return totalDiff;
};

export const getTimeString = (hours, minutes, seconds) => {
  return `${getDoubleDigitTime(hours)}:${getDoubleDigitTime(
    minutes
  )}:${getDoubleDigitTime(seconds)}`;
};

export const parseTotalTime = (timeSpent) => {
  if (timeSpent) {
    return getTimeString(
      timeSpent.hours,
      timeSpent.minutes,
      parseInt(timeSpent.seconds)
    );
  }
  return "00:00:00";
};

const getDoubleDigitTime = (value) => {
  const first = value >= 10 ? value.toString()[0] : "0";
  const second = value >= 10 ? value.toString()[1] : value.toString();
  return `${first}${second}`;
};
