import { PuzzleIcon } from "@heroicons/react/outline";
import React from "react";
import { useSetRecoilState } from "recoil";
import { addPuzzleModalAtom } from "../../atoms/modalAtom";
import AvatarDropdown from "./AvatarDropdown";

function Header() {
  const setIsOpen = useSetRecoilState(addPuzzleModalAtom);

  return (
    <nav className="bg-neutral-800 shadow">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <PuzzleIcon className="h-8 w-8" />
            <span className="text-xl ml-3">Puzzle Time</span>
            <div className="flex">
              <div className="ml-2 md:ml-10 flex items-baseline space-x-4">
                <a
                  className="hidden md:block hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                  href="/puzzles"
                >
                  Puzzles
                </a>
                <button
                  className="hover:text-gray-300 text-white px-3 py-2 rounded-md text-sm font-medium bg-green-700"
                  onClick={() => setIsOpen(true)}
                >
                  Add Puzzle
                </button>
              </div>
            </div>
          </div>
          <div>
            <AvatarDropdown />
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
