import { atom } from "recoil";

export const puzzleAtom = atom({
  key: "puzzles",
  default: [],
});

export const selectedPuzzleAtom = atom({
  key: "selectedPuzzle",
  default: null,
});
