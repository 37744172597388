import React from "react";
import { Dialog } from "@headlessui/react";
import { useRecoilState, useRecoilValue } from "recoil";
import { addPuzzleModalAtom } from "../../atoms/modalAtom";
import { authAtom } from "../../atoms/authAtom";
import AddPuzzleForm from "./AddPuzzleForm";
import { supabase } from "../../services/supabase";
import { useNavigate } from "react-router-dom";

function AddPuzzleModal() {
  const [isOpen, setIsOpen] = useRecoilState(addPuzzleModalAtom);
  const user = useRecoilValue(authAtom);
  const navigator = useNavigate();

  const onCancel = () => {
    setIsOpen(false);
  };

  const onSubmit = async (values) => {
    try {
      const puzzle = {
        name: values.name,
        piece_count: values.pieceCount,
        user_id: user.id,
      };
      let { error } = await supabase
        .from("puzzles")
        .upsert(puzzle, { returning: "minimal" });
      if (error) {
        throw error;
      }
      setIsOpen(false);
    } catch (error) {
      console.log(error);
    } finally {
      navigator(0);
    }
  };

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-10 overflow-y-auto"
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <div className="min-h-screen px-4 text-center">
        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75" />

        <span className="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>

        <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-neutral-900 text-gray-300 shadow-xl rounded-2xl">
          <div className="flex flex-col items-center">
            <Dialog.Title as="h3" className="text-xl font-medium leading-6">
              Add Puzzle
            </Dialog.Title>

            <div className="mt-6">
              <AddPuzzleForm
                cancelCallback={onCancel}
                submitCallback={onSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default AddPuzzleModal;
